import {
  Blue_5Step,
  Blue_5Step_0_1,
  SelectedTessela,
  FloodMaps_Bathy,
  Green,
  Grey,
  Blue_5Step_Pop,
  Red_5Step,
  Blue_5Step_Verra_Pop,
  Blue_5Step_Verra_Property,
} from "./colormaps/colormaps";
import {
  ConfigurableLayerMap,
  Filter,
  Layer,
  LayerGroup,
  LayerGroupName,
  LayerName,
} from "types/dataModel";
import { Icon } from "@iconify/react";
import { RP } from "./floodconf";
import { BREADCRUMB_ICON_SIZE } from "hooks/useBreadcrumbs";

export const COUNTRY_TESELA_ZOOM_SWITCH = 0;
export const FLOODING_MIN_ZOOM = 3;

export const REFERENCE_YEAR = 1996;
export const LATEST_YEAR = 2020;

export const initialYear = LATEST_YEAR;
export const benStock = ["to-number", ["get", `Ben_Stock_${LATEST_YEAR}`]];
const riskStock = ["to-number", ["get", `Risk_Stock_${LATEST_YEAR}`]];
const benPop = ["to-number", ["get", `Ben_Pop_${LATEST_YEAR}`]];
const nomangRiskStock = ["+", benStock, riskStock];
const riskReductionRatio = [
  "case",
  ["==", nomangRiskStock, 0],
  0,
  ["-", 1, ["to-number", ["/", riskStock, nomangRiskStock]]],
];

export const mangHaPercChange = [
  "/",
  [
    "-",
    ["to-number", ["get", `Mang_Ha_${LATEST_YEAR}`]],
    ["to-number", ["get", `Mang_Ha_${REFERENCE_YEAR}`]],
  ],
  ["to-number", ["get", `Mang_Ha_${REFERENCE_YEAR}`]],
];

export const mangHaTotalChange = [
  "-",
  ["to-number", ["get", `Mang_Ha_${LATEST_YEAR}`]],
  ["to-number", ["get", `Mang_Ha_${REFERENCE_YEAR}`]],
];

const verraPeopleResilienceCredits = [
  "/",
  ["to-number", ["get", `Ben_Pop_${LATEST_YEAR}`]],
  ["to-number", ["get", `Mang_Ha_${LATEST_YEAR}`]],
];

const verraPropertyResilienceCredits = [
  "/",
  ["to-number", ["get", `Ben_Stock_${LATEST_YEAR}`]],
  ["to-number", ["get", `Mang_Ha_${LATEST_YEAR}`]],
];

export const FILTER_VALUE = 1;
export const FILTER = [">", riskStock, FILTER_VALUE] as Filter;

export const VERRA_PEOPLE_RESILIENCE_CREDITS_FILTER = [
  ">",
  verraPeopleResilienceCredits,
  0,
] as Filter;

export const VERRA_PROPERTY_RESILIENCE_CREDITS_FILTER = [
  ">",
  verraPropertyResilienceCredits,
  0,
] as Filter;

export const LAYERS: Record<LayerName, Layer> = {
  // Existing Risk
  [LayerName.TESSELA_BOUNDS_EXISTING_RISK]: {
    id: "tessela_bounds",
    source: "CWON_RESULTS_TESELA_ALLYEARS",
    source_layer: "CWON_RESULTS_TESELA_ALLYEARS",
    legend: SelectedTessela,
    layer_title: "Tessela",
    layer_type: "SIMPLE_OUTLINE",
    selection_dependent_on: "CWON_RESULTS_TESELA_ALLYEARS_RepPts",
    filter: FILTER,
    minzoom: COUNTRY_TESELA_ZOOM_SWITCH,
  },
  [LayerName.TESSELA_RPS_EXISTING_RISK]: {
    id: "tessela_rps",
    source: "CWON_RESULTS_TESELA_ALLYEARS_RepPt",
    source_layer: "CWON_RESULTS_TESELA_ALLYEARS_RepPts",
    colorValue: riskStock,
    legend: Red_5Step,
    layer_title: `Annual Expected Risk ${LATEST_YEAR}`,
    layer_type: "DISCRETE_POINT",
    legend_prefix: "$",
    format: "$",
    is_selectable: true,
    filter: FILTER,
    selection_sync_with: "CWON_RESULTS_TESELA_ALLYEARS_Hex",
    minzoom: COUNTRY_TESELA_ZOOM_SWITCH,
  },
  // Stock Benefits
  [LayerName.TESSELA_BOUNDS_STOCK_BENEFITS]: {
    id: "tessela_bounds",
    source: "CWON_RESULTS_TESELA_ALLYEARS",
    source_layer: "CWON_RESULTS_TESELA_ALLYEARS",
    legend: SelectedTessela,
    layer_title: "Tessela",
    layer_type: "SIMPLE_OUTLINE",
    selection_dependent_on: "CWON_RESULTS_TESELA_ALLYEARS_RepPts",
    filter: FILTER,
  },
  [LayerName.TESSELA_RPS_STOCK_BENEFITS]: {
    id: "tessela_rps",
    source: "CWON_RESULTS_TESELA_ALLYEARS_RepPt",
    source_layer: "CWON_RESULTS_TESELA_ALLYEARS_RepPts",
    colorValue: benStock,
    legend: Blue_5Step,
    layer_title: `Annual Expected Benefit ${LATEST_YEAR}`,
    layer_type: "DISCRETE_POINT",
    legend_prefix: "$",
    format: "$",
    is_selectable: true,
    filter: FILTER,
    selection_sync_with: "CWON_RESULTS_TESELA_ALLYEARS_Hex",
    minzoom: COUNTRY_TESELA_ZOOM_SWITCH,
  },
  // Pop Benefits
  [LayerName.TESSELA_BOUNDS_POPULATION_BENEFITS]: {
    id: "tessela_bounds",
    source: "CWON_RESULTS_TESELA_ALLYEARS",
    source_layer: "CWON_RESULTS_TESELA_ALLYEARS",
    legend: SelectedTessela,
    layer_title: "Tessela",
    layer_type: "SIMPLE_OUTLINE",
    selection_dependent_on: "CWON_RESULTS_TESELA_ALLYEARS_RepPts",
    minzoom: COUNTRY_TESELA_ZOOM_SWITCH,
    filter: FILTER,
  },
  [LayerName.TESSELA_RPS_POPULATION_BENEFITS]: {
    id: "tessela_rps",
    source: "CWON_RESULTS_TESELA_ALLYEARS_RepPt",
    source_layer: "CWON_RESULTS_TESELA_ALLYEARS_RepPts",
    colorValue: benPop,
    legend: Blue_5Step_Pop,
    layer_title: `Annual Population Benefit ${LATEST_YEAR}`,
    layer_type: "DISCRETE_POINT",
    legend_prefix: "",
    is_selectable: true,
    minzoom: COUNTRY_TESELA_ZOOM_SWITCH,
    selection_sync_with: "CWON_RESULTS_TESELA_ALLYEARS_Hex",
    filter: FILTER,
  },
  // Risk Reduction Ratio
  [LayerName.TESSELA_BOUNDS_RISK_REDUCTION_RATIO]: {
    id: "tessela_bounds",
    source: "CWON_RESULTS_TESELA_ALLYEARS",
    source_layer: "CWON_RESULTS_TESELA_ALLYEARS",
    legend: SelectedTessela,
    layer_title: "Tessela",
    layer_type: "SIMPLE_OUTLINE",
    selection_dependent_on: "CWON_RESULTS_TESELA_ALLYEARS_Hex",
    minzoom: COUNTRY_TESELA_ZOOM_SWITCH,
    filter: FILTER,
  },
  [LayerName.HEX]: {
    id: "hex",
    source: "CWON_RESULTS_TESELA_ALLYEARS_Hex",
    source_layer: "CWON_RESULTS_TESELA_ALLYEARS_Hex",
    legend: Blue_5Step_0_1,
    colorValue: riskReductionRatio,
    heightValue: nomangRiskStock,
    baseValue: riskStock,
    scale: 0.3,
    layer_title: `Risk Reduction ${LATEST_YEAR}`,
    layer_type: "HEX_3D",
    hex_type: "REDUCTION",
    legend_suffix: "%",
    format: "%",
    display_legend: true,
    is_selectable: true,
    selection_sync_with: "CWON_RESULTS_TESELA_ALLYEARS_RepPts",
    minzoom: COUNTRY_TESELA_ZOOM_SWITCH,
    filter: FILTER,
  },
  [LayerName.HEX2]: {
    id: "hex2",
    source: "CWON_RESULTS_TESELA_ALLYEARS_Hex",
    source_layer: "CWON_RESULTS_TESELA_ALLYEARS_Hex",
    legend: Blue_5Step_0_1,
    colorValue: "white",
    heightValue: riskStock,
    baseValue: 0,
    scale: 0.3,
    layer_title: "Tessela",
    layer_type: "HEX_3D",
    hex_type: "BASE",
    legend_suffix: "%",
    format: "%",
    display_legend: false,
    is_selectable: true,
    selection_sync_with: "CWON_RESULTS_TESELA_ALLYEARS_RepPts",
    minzoom: COUNTRY_TESELA_ZOOM_SWITCH,
    filter: FILTER,
  },
  // Flooding
  [LayerName.MANGROVES_NOMANG]: {
    id: "mangroves_nomang",
    source: "mangroves_2020",
    source_layer: "GMW_2020_v3",
    layer_title: "No Mangroves",
    colorValue: ["to-number", ["get", "PXLVAL"]],
    legend: Grey,
    layer_type: "FILL_WITH_OUTLINE",
    display_legend: false,
    subgroup: "flooding_nomang",
    opacity: 0.4,
    minzoom: FLOODING_MIN_ZOOM,
    maxzoom: 18,
  },
  [LayerName.MANGROVES_LATEST]: {
    id: "mangroves_latest",
    source: "mangroves_2020",
    source_layer: "GMW_2020_v3",
    layer_title: `Mangroves ${LATEST_YEAR}`,
    colorValue: ["to-number", ["get", "PXLVAL"]],
    legend: Green,
    layer_type: "FILL_WITH_OUTLINE",
    display_legend: false,
    subgroup: "flooding_latest",
    opacity: 0.4,
    minzoom: FLOODING_MIN_ZOOM,
    maxzoom: 18,
  },
  [LayerName.FLOODING_NOMANG]: {
    id: "flooding_nomang",
    source: "flooding_2020_nomang_pt",
    source_layer: `without_2020_${RP}`,
    legend: FloodMaps_Bathy,
    colorValue: ["to-number", ["get", `without_2020_${RP}`]],
    layer_title: "Expected Flooding",
    layer_subtitle: `1 in ${parseInt(RP)} year storm`,
    layer_toggle: "Show Flooding without Mangroves",
    display_legend: true,
    layer_type: "FILL_WITH_OUTLINE",
    legend_suffix: "m",
    subgroup: "flooding_nomang",
    minzoom: FLOODING_MIN_ZOOM,
    maxzoom: 18,
    filter: [">", ["to-number", ["get", `without_2020_${RP}`]], 0],
  },
  [LayerName.FLOODING_LATEST]: {
    id: "flooding_latest",
    source: "flooding_2020_pt",
    source_layer: `with_2020_${RP}`,
    legend: FloodMaps_Bathy,
    colorValue: ["to-number", ["get", `without_2020_${RP}`]], //bug in generation, data for "with" is labelled "without"
    layer_title: "Expected Flooding",
    layer_subtitle: `1 in ${parseInt(RP)} year storm`,
    layer_toggle: "Show Flooding with Mangroves",
    layer_type: "FILL_WITH_OUTLINE",
    display_legend: false,
    legend_suffix: "m",
    subgroup: "flooding_latest",
    minzoom: FLOODING_MIN_ZOOM,
    maxzoom: 18,
    filter: [">", ["to-number", ["get", `without_2020_${RP}`]], 0], //bug in generation, data for "with" is labelled "without"
  },
  // Verra People Resilience Credits
  [LayerName.TESSELA_BOUNDS_VERRA_PEOPLE_RESILIENCE_CREDITS]: {
    id: "tessela_bounds",
    source: "CWON_RESULTS_TESELA_ALLYEARS",
    source_layer: "CWON_RESULTS_TESELA_ALLYEARS",
    legend: SelectedTessela,
    layer_title: "Tessela",
    layer_type: "SIMPLE_OUTLINE",
    selection_dependent_on: "CWON_RESULTS_TESELA_ALLYEARS_RepPts",
    minzoom: COUNTRY_TESELA_ZOOM_SWITCH,
    filter: VERRA_PEOPLE_RESILIENCE_CREDITS_FILTER,
  },
  [LayerName.TESSELA_RPS_VERRA_PEOPLE_RESILIENCE_CREDITS]: {
    id: "tessela_rps",
    source: "CWON_RESULTS_TESELA_ALLYEARS_RepPt",
    source_layer: "CWON_RESULTS_TESELA_ALLYEARS_RepPts",
    colorValue: verraPeopleResilienceCredits,
    legend: Blue_5Step_Verra_Pop,
    layer_title: `People Benefits per hectare per year`,
    layer_type: "DISCRETE_POINT",
    is_selectable: true,
    minzoom: COUNTRY_TESELA_ZOOM_SWITCH,
    selection_sync_with: "CWON_RESULTS_TESELA_ALLYEARS_Hex",
    filter: VERRA_PEOPLE_RESILIENCE_CREDITS_FILTER,
  },
  // Verra Property Resilience Credits
  [LayerName.TESSELA_BOUNDS_VERRA_PROPERTY_RESILIENCE_CREDITS]: {
    id: "tessela_bounds",
    source: "CWON_RESULTS_TESELA_ALLYEARS",
    source_layer: "CWON_RESULTS_TESELA_ALLYEARS",
    legend: SelectedTessela,
    layer_title: "Tessela",
    layer_type: "SIMPLE_OUTLINE",
    selection_dependent_on: "CWON_RESULTS_TESELA_ALLYEARS_RepPts",
    minzoom: COUNTRY_TESELA_ZOOM_SWITCH,
    filter: VERRA_PROPERTY_RESILIENCE_CREDITS_FILTER,
  },
  [LayerName.TESSELA_RPS_VERRA_PROPERTY_RESILIENCE_CREDITS]: {
    id: "tessela_rps",
    source: "CWON_RESULTS_TESELA_ALLYEARS_RepPt",
    source_layer: "CWON_RESULTS_TESELA_ALLYEARS_RepPts",
    colorValue: verraPropertyResilienceCredits,
    legend: Blue_5Step_Verra_Property,
    layer_title: `Econ Benefits per hectare per year`,
    layer_type: "DISCRETE_POINT",
    legend_prefix: "$",
    is_selectable: true,
    minzoom: COUNTRY_TESELA_ZOOM_SWITCH,
    selection_sync_with: "CWON_RESULTS_TESELA_ALLYEARS_Hex",
    filter: VERRA_PROPERTY_RESILIENCE_CREDITS_FILTER,
  },
};

const currentRiskLayers: ConfigurableLayerMap = {
  [LayerName.MANGROVES_LATEST]: {
    toggleLabel: "Show mangroves (2020)",
    toggleKey: "mangrove_extent",
  },
  [LayerName.TESSELA_BOUNDS_EXISTING_RISK]: true,
  [LayerName.TESSELA_RPS_EXISTING_RISK]: true,
};

const annualBenefitsLayers: ConfigurableLayerMap = {
  [LayerName.MANGROVES_LATEST]: {
    toggleLabel: "Show mangroves (2020)",
    toggleKey: "mangrove_extent",
  },
  [LayerName.TESSELA_BOUNDS_STOCK_BENEFITS]: true,
  [LayerName.TESSELA_RPS_STOCK_BENEFITS]: true,
};

const reductRatioLayers: ConfigurableLayerMap = {
  [LayerName.MANGROVES_LATEST]: {
    toggleLabel: "Show mangroves (2020)",
    toggleKey: "mangrove_extent",
  },
  [LayerName.TESSELA_BOUNDS_RISK_REDUCTION_RATIO]: true,
  [LayerName.HEX]: true,
  [LayerName.HEX2]: true,
};

const floodingComparisonLayers: ConfigurableLayerMap = {
  [LayerName.MANGROVES_NOMANG]: {
    slidePosition: "right",
    toggleKey: "mangrove_extent",
    toggleLabel: "Show mangroves (2020)",
  },
  [LayerName.MANGROVES_LATEST]: {
    slidePosition: "left",
    toggleKey: "mangrove_extent",
    toggleLabel: "Show mangroves (2020)",
  },
  [LayerName.FLOODING_NOMANG]: {
    slidePosition: "right",
    slideKey: "flooding",
    slideLabel: "No Mang.",
  },
  [LayerName.FLOODING_LATEST]: {
    slidePosition: "left",
    slideKey: "flooding",
    slideLabel: "2020 Mang.",
  },
};

const populationLayers: ConfigurableLayerMap = {
  [LayerName.MANGROVES_LATEST]: {
    toggleLabel: "Show mangroves (2020)",
    toggleKey: "mangrove_extent",
  },
  [LayerName.TESSELA_BOUNDS_POPULATION_BENEFITS]: true,
  [LayerName.TESSELA_RPS_POPULATION_BENEFITS]: true,
};

const benefitsPerHectare: ConfigurableLayerMap = {
  [LayerName.MANGROVES_LATEST]: {
    toggleLabel: "Show mangroves (2020)",
    toggleKey: "mangrove_extent",
  },
  [LayerName.TESSELA_BOUNDS_VERRA_PEOPLE_RESILIENCE_CREDITS]: {
    selectKey: "benefit_type",
    selectPosition: "left",
    selectLabel: "People",
  },
  [LayerName.TESSELA_RPS_VERRA_PEOPLE_RESILIENCE_CREDITS]: {
    selectKey: "benefit_type",
    selectPosition: "left",
    selectLabel: "People",
  },
  [LayerName.TESSELA_BOUNDS_VERRA_PROPERTY_RESILIENCE_CREDITS]: {
    selectKey: "benefit_type",
    selectPosition: "right",
    selectLabel: "Economic",
  },
  [LayerName.TESSELA_RPS_VERRA_PROPERTY_RESILIENCE_CREDITS]: {
    selectKey: "benefit_type",
    selectPosition: "right",
    selectLabel: "Economic",
  },
};

const layerGroups: Record<LayerGroupName, LayerGroup> = {
  [LayerGroupName.CurrentRisk]: {
    name: LayerGroupName.CurrentRisk,
    shortDescription:
      "The current annual dollar value at risk from coastal flooding, with existing mangroves.",
    IconComponent: () => (
      <Icon icon="mdi:hazard-lights" color="white" className="w-full h-full" />
    ),
    IconComponentHTML: `<iconify-icon icon="mdi:hazard-lights" class="breadcrumbs-icon" width="${BREADCRUMB_ICON_SIZE}px" height="${BREADCRUMB_ICON_SIZE}px"></iconify-icon>`,
    layers: currentRiskLayers,
    metricKey: `Risk_Stock_${LATEST_YEAR}`,
    units: "in damage per year",
  },
  [LayerGroupName.BenefitAEB]: {
    name: LayerGroupName.BenefitAEB,
    shortDescription:
      "The benefit provided by existing mangroves in reducing annual dollar value at risk",
    IconComponent: () => (
      <Icon icon="ph:hand-coins" color="white" className="w-full h-full" />
    ),
    IconComponentHTML: `<iconify-icon icon="ph:hand-coins" class="breadcrumbs-icon" width="${BREADCRUMB_ICON_SIZE}px" height="${BREADCRUMB_ICON_SIZE}px"></iconify-icon>`,
    layers: annualBenefitsLayers,
    metricKey: `Ben_Stock_${LATEST_YEAR}`,
    units: "in damage reduced per year",
  },
  [LayerGroupName.RiskReduction]: {
    name: LayerGroupName.RiskReduction,
    shortDescription:
      "The % of current annual dollar value at risk protected by mangroves.",
    IconComponent: () => (
      <Icon
        icon="lucide:git-compare"
        color="white"
        className="w-5/6 h-5/6 mx-auto"
      />
    ),
    IconComponentHTML: `<iconify-icon icon="lucide:git-compare" class="breadcrumbs-icon" width="${BREADCRUMB_ICON_SIZE}px" height="${BREADCRUMB_ICON_SIZE}px"></iconify-icon>`,
    layers: reductRatioLayers,
  },
  [LayerGroupName.Flooding]: {
    name: LayerGroupName.Flooding,
    shortDescription:
      "Flood Depth (m) with and without mangroves for 1 in 50 year storm event.",
    IconComponent: () => (
      <Icon
        icon="ri:flood-line"
        color="white"
        className="w-5/6 h-5/6 mx-auto"
      />
    ),
    IconComponentHTML: `<iconify-icon icon="ri:flood-line" class="breadcrumbs-icon" width="${BREADCRUMB_ICON_SIZE}px" height="${BREADCRUMB_ICON_SIZE}px"></iconify-icon>`,
    layers: floodingComparisonLayers,
  },
  [LayerGroupName.Population]: {
    name: LayerGroupName.Population,
    shortDescription:
      "The benefit provided by existing mangroves in reducing annual population at risk.",
    IconComponent: () => (
      <Icon
        icon="pepicons-pencil:people"
        color="white"
        className="w-full h-full -translate-y-1"
      />
    ),
    IconComponentHTML: `<iconify-icon icon="pepicons-pencil:people" class="breadcrumbs-icon" width="${BREADCRUMB_ICON_SIZE}px" height="${BREADCRUMB_ICON_SIZE}px"></iconify-icon>`,
    layers: populationLayers,
    metricKey: `Ben_Pop_${LATEST_YEAR}`,
    units: "in reduced exposure per year",
  },
  [LayerGroupName.BenefitsPerHectare]: {
    name: LayerGroupName.BenefitsPerHectare,
    shortDescription:
      "The benefits to people and property per hectare of mangroves (used to calculate Resilience Credits).",
    IconComponent: () => (
      <Icon
        icon="gis:layer-landcover"
        color="white"
        className="w-full h-full "
      />
    ),
    metricKey: `Ben_Stock_${LATEST_YEAR}`,
    units: "in damage reduced per year",
    layers: benefitsPerHectare,
  },
};

export const layersByGroup = Object.values(layerGroups).reduce(
  (acc, group) => {
    acc[group.name] = group.layers;
    return acc;
  },
  {} as Record<LayerGroupName, LayerName[] | ConfigurableLayerMap>,
);

export default layerGroups;
