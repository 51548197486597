import { useMemo, useState } from "react";
import { kFormatter } from "hooks/utils/formattingUtils";
import "./selected-features-panel.css";
import "./stats-panel-container.css";

import layerGroups from "layers/layers";
import { LayerGroupName, LayerName } from "types/dataModel";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import { MultiSwitch, MultiSwitchOption } from "components/multi-switch";
import popBenToMangroveRatio from "data/pop_ben_to_mangrove_ratio.json";
import stockBenToMangroveRatio from "data/stock_ben_to_mangrove_ratio.json";

export const VERRA_REF_YEAR = 2015;

function MetricContent({
  children,
  contentModifier,
}: {
  children: React.ReactNode;
  contentModifier?: string;
}) {
  return (
    <div
      className={
        "aeb-content-container " + (contentModifier ? contentModifier : "")
      }
    >
      {children}
    </div>
  );
}

function MetricTitle({
  title,
  Icon,
  selected = false,
  clickable = false,
}: {
  title: string;
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  selected?: boolean;
  clickable?: boolean;
}) {
  return (
    <div
      className={
        "aeb-container-title" +
        (selected ? " selected" : "") +
        (clickable ? " clickable" : "")
      }
    >
      <div className="layer-image-container">
        <Icon />
      </div>
      <div className="aeb-title-text-container">
        <h4 className="text-white">{title}</h4>
      </div>
    </div>
  );
}

function TemplateMetricContainer({
  title,
  icon,
  children,
  contentModifier,
  description = "Metric specific description to come here. Only shown when section is hovered.",
}: {
  title: string;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  children: React.ReactNode;
  contentModifier?: string;
  description?: string;
}) {
  return (
    <div className="aeb-container text-left">
      <MetricTitle Icon={icon} title={title} />
      <MetricContent contentModifier={contentModifier}>
        <p className="aeb-container-metric-description px-2">{description}</p>
        {children}
      </MetricContent>
    </div>
  );
}

enum ResilienceCreditType {
  PROPERTY = "PROPERTY",
  PEOPLE = "PEOPLE",
}

function ResilienceCreditCalculator({
  resilienceCreditsPerHectare,
  hectares,
  setHectares,
  type = ResilienceCreditType.PROPERTY,
}: {
  resilienceCreditsPerHectare: number;
  hectares: number;
  setHectares: (hectares: number) => void;
  type: ResilienceCreditType;
}) {
  const totalValue = hectares * resilienceCreditsPerHectare;

  return (
    <div className="w-full flex flex-col items-center pl-2 pr-8">
      <div className="w-full flex justify-between mb-4">
        <div className="w-1/2 pr-2 flex flex-col items-center">
          <label className="block text-right label-large">
            Project Size (Hectares):
          </label>
          <input
            type="number"
            value={hectares}
            onChange={(e) => setHectares(Number(e.target.value))}
            className="w-1/2 border p-1 text-lg text-center lining-nums"
          />
        </div>
        <div className="w-1/2 pl-2 flex flex-col items-end">
          <label className="block text-right label-large">
            Benefits per Hectare:
          </label>
          <div className="w-1/2 flex items-center p-1">
            {type === ResilienceCreditType.PROPERTY && (
              <span className="mr-1">$</span>
            )}
            <h6 className="text-xl">
              {kFormatter(resilienceCreditsPerHectare, "$", 0)}
            </h6>
            <span className="mr-1">&nbsp;</span>

            {type === ResilienceCreditType.PEOPLE && (
              <span className="mr-1 text-lg pt-2"></span>
            )}
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between mb-2">
        <div className="w-full pl-2">
          <label className="block text-center label-large text-xl">
            Total Credit Value:
          </label>
          <h6 className="w-full text-center font-bold text-shoreline text-xl">
            {type === ResilienceCreditType.PROPERTY && "$"}
            {kFormatter(totalValue)}
            {type === ResilienceCreditType.PEOPLE && " People"}
          </h6>
        </div>
      </div>
    </div>
  );
}

function generateComparisonText(
  studyUnitValue: number,
  countryValue: number,
  worldValue: number,
  countryName: string,
  creditsType: "people" | "property",
): string {
  const studyToCountry = ((studyUnitValue - countryValue) / countryValue) * 100;
  const studyToWorld = ((studyUnitValue - worldValue) / worldValue) * 100;

  let text = `Mangrove restoration in this study unit has potential to provide ${studyUnitValue.toFixed(
    0,
  )} ${creditsType} Benefits per Hectare of restoration annually. `;

  if (Math.abs(studyToCountry) < 1) {
    text += `This value is on par with the ${countryName} median of ${countryValue.toFixed(
      0,
    )} Benefits per Hectare of restoration. `;
  } else {
    text += `It is ${
      Math.abs(studyToCountry) > 1000
        ? "much"
        : `${Math.abs(studyToCountry).toFixed(0)}%`
    } ${
      studyToCountry > 0 ? "higher" : "lower"
    } than the ${countryName} median of ${countryValue.toFixed(
      0,
    )} benefits per hectare. `;
  }

  if (Math.abs(studyToWorld) < 1) {
    text += ` It is also on par with the world median of ${worldValue.toFixed(
      0,
    )} benefits per hectare.`;
  } else {
    text += ` Compared to the world median of ${worldValue.toFixed(
      0,
    )} benefits per hectare, it is ${
      Math.abs(studyToWorld) > 1000
        ? "much"
        : `${Math.abs(studyToWorld).toFixed(0)}%`
    } ${studyToWorld > 0 ? "higher" : "lower"}.`;
  }

  return text;
}

const sum = (acc: number, cur: number) => acc + cur;

const reducers = {
  sum: (values: number[]) => values.reduce(sum, 0),
};

const getStat = (
  metric: string,
  selectedFeatures: MapboxGeoJSONFeature[],
  distinct = false,
) => {
  const values = selectedFeatures.map((x) => x.properties?.[metric]);
  const uniqueValues = distinct ? [...new Set(values)] : values;
  return reducers.sum(uniqueValues);
};

const VERRA_LAYER_NAMES = {
  people: [
    LayerName.TESSELA_RPS_VERRA_PEOPLE_RESILIENCE_CREDITS,
    LayerName.TESSELA_BOUNDS_VERRA_PEOPLE_RESILIENCE_CREDITS,
  ],
  property: [
    LayerName.TESSELA_RPS_VERRA_PROPERTY_RESILIENCE_CREDITS,
    LayerName.TESSELA_BOUNDS_VERRA_PROPERTY_RESILIENCE_CREDITS,
  ],
};

function SelectedFeaturesVerraPanel({
  selectedFeatures,
  layersToggle,
  toggleLayer,
}: {
  selectedFeatures: MapboxGeoJSONFeature[];
  layersToggle: Record<string, boolean>;
  toggleLayer: (layer: LayerName) => void;
}) {
  const [hectares, setHectares] = useState<number>(0);
  const selectedCountries = useMemo(() => {
    return [
      ...new Set(
        selectedFeatures.map((feature) => feature.properties?.Country),
      ),
    ];
  }, [selectedFeatures]);

  const filteredPopBenData = useMemo(() => {
    return popBenToMangroveRatio.countries.filter((country) =>
      selectedCountries.includes(country.Country),
    );
  }, [selectedCountries]);

  const mangrovesLatest = useMemo(
    () => getStat(`Mang_Ha_${VERRA_REF_YEAR}`, selectedFeatures),
    [selectedFeatures],
  );

  const stockLatest = useMemo(
    () => getStat(`Ben_Stock_${VERRA_REF_YEAR}`, selectedFeatures),
    [selectedFeatures],
  );

  const popLatest = useMemo(
    () => getStat(`Ben_Pop_${VERRA_REF_YEAR}`, selectedFeatures),
    [selectedFeatures],
  );

  const peopleResilinceCredits = useMemo(
    () => popLatest / mangrovesLatest,
    [mangrovesLatest, popLatest],
  );

  const propertyResilinceCredits = useMemo(
    () => stockLatest / mangrovesLatest,
    [mangrovesLatest, stockLatest],
  );

  const mode: "people" | "property" = layersToggle[
    LayerName.TESSELA_RPS_VERRA_PEOPLE_RESILIENCE_CREDITS
  ]
    ? "people"
    : "property";

  const handleSwitchUpdate = () => {
    Object.entries(VERRA_LAYER_NAMES).forEach(([key, value]) => {
      toggleLayer(value[0]);
      toggleLayer(value[1]);
    });
  };

  return (
    <>
      <TemplateMetricContainer
        icon={layerGroups[LayerGroupName.BenefitsPerHectare].IconComponent}
        description="Resilience Credits are calculated based on the benefits to people and property per hectare of restored mangroves."
        title="Resilience Credits"
      >
        <div className="flex w-full justify-center">
          <MultiSwitch
            onChange={handleSwitchUpdate}
            selection={mode}
            theme="light"
          >
            <MultiSwitchOption title="Social" value="people" position="left" />
            <MultiSwitchOption
              title="Economic"
              value="property"
              position="right"
            />
          </MultiSwitch>
        </div>
        <div className="flex align-center flex-wrap w-full">
          {mode === "people" && (
            <>
              <h5 className="ml-2 mt-4 mb-2 w-full">
                Resilience Credit Calculator:
              </h5>
              <ResilienceCreditCalculator
                hectares={hectares}
                setHectares={setHectares}
                resilienceCreditsPerHectare={peopleResilinceCredits}
                type={ResilienceCreditType.PEOPLE}
              />
              <h5 className="ml-2 mb-1 mt-2 w-full">
                Annual Benefits per Hectare:
              </h5>
              <p className="w-3/5 italic text-right">Benefits (Social):</p>
              <p className="ml-2 lining-nums">{kFormatter(popLatest)}</p>
              <p className="w-3/5 italic text-right">
                <span style={{ fontSize: "1.6em" }}>÷ </span>
                Hectares of Mangroves:
              </p>
              <p className="ml-2 lining-nums">{kFormatter(mangrovesLatest)}</p>
              <div className="w-3/5 my-1 -translate-x-2 mx-auto pr-4 self-center	 border-solid border-t-[1px] border-trench" />
              <p className="w-3/5 italic text-right">Benefits per Hectare:</p>
              <p className="ml-2 lining-nums">
                <b>{kFormatter(peopleResilinceCredits)}</b>
              </p>
            </>
          )}
          {mode === "property" && (
            <>
              <h5 className="ml-2 mt-4 mb-2 w-full">
                Resilience Credit Calculator:
              </h5>
              <ResilienceCreditCalculator
                hectares={hectares}
                setHectares={setHectares}
                resilienceCreditsPerHectare={propertyResilinceCredits}
                type={ResilienceCreditType.PROPERTY}
              />
              <br></br>
              <br></br>
              <h5 className="ml-2 mb-1 mt-2 w-full">
                Annual Benefits per Hectare:
              </h5>
              <p className="w-3/5 italic text-right">Benefits (Economic):</p>
              <p className="ml-2 lining-nums">
                ${kFormatter(stockLatest, "$", 0)}
              </p>
              <p className="w-3/5 italic text-right">
                <span style={{ fontSize: "1.6em" }}>÷ </span>
                Hectares of Mangroves:
              </p>
              <p className="ml-2 lining-nums">{kFormatter(mangrovesLatest)}</p>
              <div className="w-3/5 my-1 -translate-x-2 mx-auto pr-4 self-center	 border-solid border-t-[1px] border-trench" />
              <p className="w-3/5 italic text-right">Benefits per Hectare:</p>
              <p className="ml-2 lining-nums">
                <b>${kFormatter(propertyResilinceCredits, "$", 0)}</b>
              </p>
            </>
          )}
        </div>
      </TemplateMetricContainer>
      <br></br>
      <div className="pb-4 bottom-citation">
        Benefits based on <i>Menéndez et al. (2020)</i> and used in Verra Deemed
        Estimate method.
      </div>
    </>
  );
}

export default SelectedFeaturesVerraPanel;
